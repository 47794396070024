import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import HorizontalScrollBox from '../HorizontalScrollBox';

import styles from './video-gallery.module.css';

const VideoGallery = ( { activeVideo, className, setActiveVideo, videos } ) => {
	const [ left, setLeft ] = useState( 0 );

	const mapThumbnails = useCallback( () => videos.map( ( { title, id }, i ) => {
		const handleScroll = () => {
			const thumbnailWidth = window.innerWidth * 0.35 > 250 ? 258 : window.innerWidth * 0.35 + 8;

			setLeft( i * thumbnailWidth );
		};

		const handleClick = () => {
			handleScroll();

			setActiveVideo( id );
		};

		return (
			<div
				className={ `${ styles.videoThumbnail } ${ activeVideo === id ? styles.active : '' } ${ className }` }
				key={ id }
				onClick={ handleClick }
				onFocus={ handleScroll }
				tabIndex={ id !== activeVideo ? '0' : '-1' }
				title={ title }
			>
				{ console.log( left ) }
				<div
					className={ styles.img }
					style={{ background: `url(http://i.ytimg.com/vi/${ id }/hqdefault.jpg) no-repeat center/cover` }}
				/>
			</div>
		);
	} ), [ activeVideo, className, left, setActiveVideo, videos ] );

	return (
		<div className={ styles.videoGallery }>
			<HorizontalScrollBox left={ left } wrapperClass={ styles.scrollingWrapper }>{ mapThumbnails() }</HorizontalScrollBox>
		</div>
	);
};

VideoGallery.defaultProps = { className: '' };

VideoGallery.propTypes = {
	activeVideo: PropTypes.string.isRequired,
	className: PropTypes.string,
	setActiveVideo: PropTypes.func.isRequired,
	videos: PropTypes.array.isRequired
};

export default VideoGallery;
