import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import horizontalScrollBoxStyles from './horizontalScrollBox.module.css';

const HorizontalScrollBox = ( { children, left, scrollbarMaskClass, wrapperClass } ) => {
	const wrapperRef = useRef( null );

	useEffect( () => {
		if ( wrapperRef.current ) {
			console.dir( wrapperRef.current );
			wrapperRef.current.scrollLeft = left;
		}
	}, [ left ] );

	return (
		<div className={ `${ horizontalScrollBoxStyles.scrollbarMask } ${ scrollbarMaskClass }` }>
			<div
				className={ `${ horizontalScrollBoxStyles.scrollingWrapper } ${ wrapperClass }` }
				ref={ wrapperRef }
			>
				{ children }
			</div>
		</div>
	);
};

HorizontalScrollBox.propTypes = {
	children: PropTypes.node.isRequired,
	left: PropTypes.number,
	scrollbarMaskClass: PropTypes.string,
	wrapperClass: PropTypes.string
};

export default HorizontalScrollBox;
