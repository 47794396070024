import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import useAlbums from '../../hooks/useAlbums';

import AlbumDetails from '../../components/AlbumDetails';
// import AlbumThumbnail from '../../components/AlbumThumbnail';
import EmbeddedVideo from '../../components/EmbeddedVideo';
import Hero from '../../components/Hero';
import Layout from '../../components/Layout';
import VideoGallery from '../../components/VideoGallery';

import styles from './music-page.module.css';

const MusicPage = ( { data } ) => {
	const {
		file: {
			childMarkdownRemark: {
				frontmatter: {
					hero: {
						image: { childImageSharp: { fluid: hero } },
						alignment
					},
					showLeaderSideman,
					videos: unmappedVideos
				},
				html: body
			}
		}
	} = data;

	const videos = useMemo( () => unmappedVideos.map( ( { frontmatter: { title, youtubeId: id } } ) => ( { title, id } ) ), [ unmappedVideos ] );

	const albums = useAlbums();
	const leaderAlbums = useMemo( () => albums.filter( album => album.leader ), [ albums ] );
	const sidemanAlbums = useMemo( () => albums.filter( album => !album.leader ), [ albums ] );

	const [ activeVideo, setActiveVideo ] = useState( videos[ 0 ].id );

	/* const mapAlbumThumbnails = useCallback( albums => albums.map( album => (
	   	<AlbumThumbnail
	   		className={ styles.thumbnail }
	   		cover={ album.frontCover }
	   		id={ album.id }
	   		key={ album.id }
	   	/>
	   ) ), [] ); */
	const mapAlbumDetails = useCallback( albums => albums.map( album => <AlbumDetails key={ album.id } {...album } /> ), [] );

	return (
		<Layout>
			{	hero
				? <Hero alignment={ alignment } fluid={ hero } />
				: null
			}
			{ body
				? <section className="separated">
					<div className="wrapper">
						<div className="body message" dangerouslySetInnerHTML={ { __html: body } } />
					</div>
				</section>
				: null
			}
			{ showLeaderSideman
				? <>
					{ leaderAlbums.length
						? <section>
							<div className="wrapper">
								<h3 className="section-header">Discography As Leader</h3>
								{/* <div className={ styles.thumbnails }>
									{ mapAlbumThumbnails( leaderAlbums ) }
								</div> */}
								{ mapAlbumDetails( leaderAlbums ) }
							</div>
						</section>
						: null
					}
					{ sidemanAlbums.length
						? <section>
							<div className="wrapper">
								<h3 className="section-header">Discography As Sideman</h3>
								{/* <div className={ styles.thumbnails }>
									{ mapAlbumThumbnails( sidemanAlbums ) }
								</div> */}
								{ mapAlbumDetails( sidemanAlbums ) }
							</div>
						</section>
						: null
					}
				</>
				: <section className="separated">
					<div className="wrapper">
						<h3 className="section-header">Selected Discography</h3>
						{/* <div className={ styles.thumbnails }>
							{ mapAlbumThumbnails( albums ) }
						</div> */}
						{ mapAlbumDetails( albums ) }
					</div>
				</section>
			}
			<section>
				<div className="wrapper">
					<h3 className="section-header">Featured Videos</h3>
					<EmbeddedVideo videoID={ activeVideo } />
					<VideoGallery
						activeVideo={ activeVideo }
						className={ styles.videoGallery }
						setActiveVideo={ setActiveVideo }
						videos={ videos }
					/>
				</div>
			</section>
		</Layout>
	);
};

MusicPage.propTypes = { data: PropTypes.object.isRequired };

export default MusicPage;

export const query = graphql`
	query MusicPage {
		file(relativePath: {eq: "pages/music.md"}) {
			childMarkdownRemark {
				frontmatter {
					hero {
						image {
							childImageSharp {
								fluid(maxWidth: 3000, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alignment
					}
					showLeaderSideman
					videos {
						frontmatter {
							title
							youtubeId
						}
					}
				}
				html
			}
		}
	}
`;
