import { useStaticQuery, graphql } from 'gatsby';

const useAlbums = ( ) => {
	let { allFile: { nodes } } = useStaticQuery( graphql`
		query Albums {
			allFile(filter: {relativeDirectory: {regex: "/^albums/.*/"}, extension: {eq: "md"}}) {
				nodes {
					childMarkdownRemark {
						frontmatter {
							title
							leader
							artist
							frontCover {
								childImageSharp {
									fluid(maxWidth: 600) {
										...GatsbyImageSharpFluid
									}
								}
							}
							backCover {
								childImageSharp {
									fluid(maxWidth: 600) {
										...GatsbyImageSharpFluid
									}
								}
							}
							links {
								title
								type
								url
							}
						}
						html
					}
					children {
						... on MarkdownRemark {
							parent {
								... on File {
									name
								}
							}
						}
					}
				}
			}
		}
	` );

	const albums = nodes.map( ( {
		childMarkdownRemark: {
			frontmatter: {
				frontCover: { childImageSharp: { fluid: frontCover } },
				backCover: { childImageSharp: { fluid: backCover } },
				...rest
			},
			html: body
		},
		children: [ { parent: { name: id } } ]
	} ) => ( {
		id,
		...rest,
		frontCover,
		backCover,
		body
	} ) );

	return albums;
};

export default useAlbums;
